<template>
	<div class="container page">
		<van-nav-bar :title="this.lottery.name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="record">
			<div class="period">
				<div class="period-number">
					<b>{{ this.lottery.next_expect }}</b> <br />
					{{ $t('end_time') }}
				</div>
				<div class="next-number">
					<van-count-down :time="time" @finish="check()" />
				</div>
			</div>
		</div>
		<div class="wrapper">
			<div class="options-bar">
				<div class="game">
					<div class="sumValueTwoSides">
						<div class="rectangle large" :class="{active: choose[v.type]}" v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name)">
							<div class="wrapper">
								<div class="content">
									<p class="name-text large">{{ v.name }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="brands">
						<div><img src="/img/lottery/a1.jpg" /></div>
						<div><img src="/img/lottery/a3.jpg" /></div>
						<div><img src="/img/lottery/a4.jpg" /></div>
						<div><img src="/img/lottery/a5.jpg" /></div>
					</div>
				</div>
			</div>
			<div class="bottom-bar">
				<div class="bar">
					<div class="left">
						<div class="item" @click="allClear()">
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7d7c7c" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M4 7h16" />
								<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
								<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
								<path d="M10 12l4 4m0 -4l-4 4" />
							</svg>
							<span class="text">{{ $t('remove') }}</span>
						</div>
						<div class="line"></div>
					</div>
					<div class="mid">
						<span class="text">{{ $t("money") }}</span>
						<span class="text num">{{ Number(this.userInfo.money).toLocaleString() }}</span>
					</div>
					<div class="right" @click="jiesuan()">
						{{ $t('send') }}
					</div>
				</div>
				<div class="wrapper" :class="{active: shopping}">
					<!--<div class="item">
                 <span class="label">Nội dung：</span>
                 <div class="bet-number">{{ this.shopchoose}}</div>
                 <van-icon name="arrow-down" :class="{ up: !shopping,down:shopping }" @click="shopping ? shopping = false : shopping = true" />
               </div>
              <div class="item">
                <span class="label">{{$t('money')}}：</span>
                <div class="amount-wrapper">
                  <van-field v-model="money" type="digit" :placeholder="$t('pls_money')" />
                </div>
              </div>-->
					<div class="item" style="justify-content: space-between;">
						<div class="part">
							<!--<span>{{ $t('total') }}</span>
                  <span class="number">{{this.formData.length}}</span>
                  <span>đặt</span>-->
							{{ $t("money") }}:
						</div>
						<div class="part">
							<div class="amount-wrapper">
								<van-field v-model="money" type="digit" :placeholder="$t('pls_money')" style="margin-right:3px" />
							</div>
						</div>
						<div class="part">
							<span>{{ $t("total") }}</span>
							<span class="number">{{ Number(this.formData.length * this.money) }}</span>
							<!--<span>đ</span>-->
						</div>
					</div>
				</div>
				<!--<div class="wrapper wrapper-step2" :class="{active:jiesuanpage}">
            <div class="title">
              <h3>{{ $t('confirm') }}</h3>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </div>
            <div class="content">
              <div class="box-content">
                <div class="line"></div>
              </div>
              <p>{{ $t('pls_confirm') }}</p>
            </div>
            <van-button class="item sub-btn" type="default" @click="doSub">{{ $t('confirm') }}</van-button>
          </div>-->
			</div>
			<van-popup v-model="jiesuanpage" get-container="body">
				<div class="head-title">
					<h3 class="text">{{ $t('confirm') }}</h3>
					<i @click="allClear()" class="close van-icon van-icon-cross"></i>
				</div>
				<div class="confirm-order-modal">
					<div class="content-confirm">
						<div>
							<div class="left">{{ this.lottery.name }} :</div>
							<div class="right">{{ this.lottery.next_expect }}</div>
						</div>
						<div>
							<div class="left">{{ $t('money') }} :</div>
							<div class="right">{{ Number(this.formData.length * this.money) }}</div>
						</div>
						<div>
							<div class="left">{{ $t('content') }} :</div>
							<div class="right">
								{{
									formData
										.map(item => {
											return item.name;
										})
										.join(", ")
								}}
							</div>
						</div>
					</div>
					<p>{{ $t('pls_confirm') }}</p>
					<div class="sub-bar">
						<van-button class="item sub-btn" type="default" @click="doSub">{{ $t('confirm') }}</van-button>
					</div>
				</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
var time;
var count = 0;
export default {
	data() {
		return {
			jiesuanpage: false,
			choose: {
				"1": false,
				"2": false,
				"3": false,
				"4": false,
			},
			playgame: false,
			shopping: false,
			isLoading: false,
			play: {},
			lottery_peilv_list: {},
			lottery_list: {},
			active: false,
			userInfo: {},
			lottery: {},
			shanzi_1: "0",
			shanzi_2: "0",
			shanzi_3: "0",
			sum: 0,
			size: "",
			double: "",
			time: 0,
			shopchoose: this.$t("not_select"),
			gameitem: "",
			formData: [],
			money: "",
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		doSub() {
			if (this.money === "0") {
				this.$toast(this.$t("pls_money"));
				return false;
			}
			if (this.formData.length === 0) {
				this.$toast(this.$t("pls_select"));
				return false;
			} else if (this.money === "") {
				this.$toast(this.$t("pls_money"));
				return false;
			} else {
				if (this.userInfo.money - this.money * this.formData.length < 0) {
					this.$toast(this.$t("not_enough"));
					return false;
				} else {
					this.$http({
						method: "post",
						data: {
							item: this.gameitem,
							money: this.money,
							lid: this.lottery.id,
							mid: this.userInfo.id,
							expect: this.lottery.now_expect,
						},
						url: "game_place_order",
					}).then(res => {
						if (res.code === 200) {
							this.$toast(res.msg);
							this.allClear();
							this.getUserInfo();
						} else if (res.code === 401) {
							this.$toast(res.msg);
						}
					});
					return true;
				}
			}
		},
		allClear() {
			for (var i = 0; i < this.formData.length; i++) {
				this.choose[this.formData[i]["type"]] = false;
			}
			this.formData.length = 0;
			this.money = "";
			this.shopchoose = this.$t("not_select");
			this.gameitem = "";
			this.shopping = false;
			this.jiesuanpage = false;
		},
		jiesuan() {
			if (this.formData.length === 0) {
				this.$toast(this.$t("pls_select"));
				return false;
			} else if (this.money === "") {
				this.$toast(this.$t("pls_money"));
				return false;
			} else {
				this.jiesuanpage ? (this.jiesuanpage = false) : (this.jiesuanpage = true);
			}
		},
		clearChooes(type) {
			for (var i = 0; i < this.formData.length; i++) {
				if (type === this.formData[i]["type"]) {
					this.formData.splice(i, 1);
					this.choose[type] = false;
				}
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]["name"];
						this.gameitem = this.formData[j]["type"];
					} else {
						this.shopchoose += "," + this.formData[j]["name"];
						this.gameitem += "," + this.formData[j]["type"];
					}
				}
			} else {
				this.shopchoose = this.$t("not_select");
				this.gameitem = "";
				this.shopping = false;
			}
			if (this.formData.length === 0) {
				this.jiesuanpage = false;
			}
		},
		choosePlay(type, name) {
			if (this.choose[type] === true) {
				this.choose[type] = false;
				for (var i = 0; i < this.formData.length; i++) {
					if (type === this.formData[i]["type"]) {
						this.formData.splice(i, 1);
					}
				}
			} else if (this.choose[type] === false) {
				this.formData.push({name: name, type: type});
				this.choose[type] = true;
			}
			if (this.formData.length === 1) {
				this.shopping = true;
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]["name"];
						this.gameitem = this.formData[j]["type"];
					} else {
						this.shopchoose += "," + this.formData[j]["name"];
						this.gameitem += "," + this.formData[j]["type"];
					}
				}
			} else {
				this.shopchoose = this.$t("not_select");
				this.gameitem = "";
				this.shopping = false;
			}
		},
		check() {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				time = window.setInterval(() => {
					setTimeout(() => {
						this.getUserInfo();
						this.getLotteryInfo();
						this.getLotteryList();
						count++;
						if (count > 5) {
							clearInterval(time);
							count = 0;
						}
					}, 0);
				}, 300);
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("renew"));
				this.getLotteryList();
				this.isLoading = false;
			}, 200);
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryPeilv() {
			this.$http({
				method: "get",
				data: {id: this.$route.query.id},
				url: "lottery_get_peilv",
			}).then(res => {
				if (res.code === 200) {
					this.lottery_peilv_list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryList() {
			this.$http({
				method: "get",
				data: {key: this.$route.query.key},
				url: "lottery_get_one_list",
			}).then(res => {
				if (res.code === 200) {
					this.lottery_list = res.data;
					this.getLotteryPeilv();
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryInfo() {
			this.$http({
				method: "get",
				data: {key: this.$route.query.key},
				url: "lottery_get_one_list",
			}).then(res => {
				if (res.code === 200) {
					if (this.time / 1000 === 59) {
						this.$toast("Bắt đầu kỳ " + this.lottery.now_expect);
					}
					if (res.data[0].opencode[0] == "1") {
						this.size = "Toys";
					} else if (res.data[0].opencode[0] == "2") {
						this.size = "Mens";
					} else if (res.data[0].opencode[0] == "3") {
						this.size = "Tech";
					} else if (res.data[0].opencode[0] == "4") {
						this.size = "Kids";
					}
					if (res.data[0].opencode[1] == "1") {
						this.double = "Toys";
					} else if (res.data[0].opencode[1] == "2") {
						this.double = "Mens";
					} else if (res.data[0].opencode[1] == "3") {
						this.double = "Tech";
					} else if (res.data[0].opencode[1] == "4") {
						this.double = "Kids";
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
			this.$http({
				method: "get",
				data: {key: this.$route.query.key},
				url: "lottery_get_info",
			}).then(res => {
				if (res.code === 200) {
					if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
						this.$toast(this.$t("pls_contact"));
						this.$router.push({path: "/Home"});
						return false;
					}
					this.lottery = res.data;
					this.time = res.data.second * 1000;
					if (this.time / 1000 === 59) {
						this.$toast(this.$t("start_period") + " " + this.lottery.now_expect);
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserInfo();
			this.getLotteryInfo();
			this.getLotteryList();
		}
	},
	destroyed() {
		clearInterval(time);
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.nav-bar .right {
	padding-left: 8px;
	padding-right: 8px;
	color: #fff;
	font-size: 14px;
	border-radius: 10px;
	border: 2px solid #fff;
	line-height: 30px;
}
.record {
	padding-left: 10px;
	padding-right: 10px;
	background-color: #fff;
	box-shadow: 0 2px 2px 0 #1c0c61;
	z-index: 1;
}
.record .period {
	text-align: center;
	padding: 20px 0;
}
.record .period .cover {
	width: 60px;
	height: 60px;
	-o-object-fit: cover;
	object-fit: cover;
}
.record .period .period-number {
	font-size: 14px;
	color: #000;
	font-weight: bold;
}
.record .period .period-number b {
	color: #d80b2a;
	margin-bottom: -10px;
	display: block;
}
.van-count-down {
	color: #ff253f;
	font-size: 20px;
	margin-top: 15px;
}
.linear-gradient {
	width: 100%;
	height: 2px;
}
.record .recent {
	display: flex;
	align-items: center;
	height: 50px;
}
.kuaisan-ball .left {
	justify-content: flex-start;
}
.kuaisan-ball {
	flex: 1;
	display: flex;
	align-items: center;
}

.kuaisan-ball .res-img {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}
.kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #000;
}
.kuaisan-ball .res-des.middle {
	width: 100px;
	font-size: 16px;
	text-align: center;
}
.van-icon {
	font-size: 20px;
}
.down {
	transition: all 0.5s;
}
.up {
	transform: rotate(180deg);
	transition: all 0.5s;
}
.wrapper {
	position: relative;
	flex: 1;
	overflow: hidden;
	background-image: url(/img/lottery/detail.jpg);
	background-size: cover;
}
.options-bar {
	display: flex;
	align-items: center;
}
.options-bar .game {
	flex: 1;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.options-bar .game .tips {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 15px;
}
.options-bar .game .tips .odds {
	flex: 1;
	font-size: 15px;
	font-weight: 500;
	color: #ff253f;
}
.options-bar .game .tips .play-tip {
	display: flex;
	align-items: center;
	height: 100%;
}
::v-deep .van-icon-more-o {
	color: #ff253f;
	font-size: 16px;
}
.options-bar .game .tips .play-tip .span-text {
	margin-left: 5px;
	font-size: 15px;
	font-weight: bold;
	color: #ff253f;
}
.linear-gradient {
	width: 100%;
	height: 2px;
}
.sumValueTwoSides {
	display: flex;
	padding: 10px 0 20px;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.rectangle {
	overflow: hidden;
}
.rectangle.large {
	width: 40%;
	margin: 10px 0 10px 6%;
	border-radius: 25px;
	box-shadow: inset 0 2px 2px #fff, 0 4px 4px #999;
	border: 1px solid #999;
}
@media (max-width: 500px) {
	.rectangle.large {
		width: 40%;
		margin: 10px 0 10px 6%;
	}
}
.rectangle.large:nth-child(n + 5) {
	display: none;
}
.rectangle .wrapper {
	position: relative;
	padding: 0 10px;
	background: linear-gradient(to right, #6f68ff, #fe8f30);
}
.rectangle .wrapper .content {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.rectangle.large .wrapper {
	padding-bottom: 40px;
}
.rectangle .wrapper .content .name-text.large {
	font-size: 14px;
}
.rectangle .wrapper .content .name-text {
	color: #fff;
	font-weight: bold;
}
.rectangle .wrapper .content .odd-text.large {
	font-size: 15px;
	margin-top: -15px;
}
.rectangle .wrapper .content .odd-text {
	text-align: center;
	color: #ff253f;
}
.bottom-bar {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	height: 55px;
	z-index: 2;
	background: #fff;
	width: 100%;
	max-width: 600px;
}
.bottom-bar .bar .left,
.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.bottom-bar .bar {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	height: 55px;
	background-color: #fff;
	z-index: 2;
}
.bottom-bar .bar .left,
.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.bottom-bar .bar .left .item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80px;
	font-size: 13px;
}
.bottom-bar .bar .left .item .text {
	font-size: 14px;
	color: #7d7c7c;
}
.jixuanico {
	font-size: 20px;
}
.bottom-bar .bar .left .line {
	width: 2px;
	height: 40px;
	background: #dadada;
}
.bottom-bar .bar .mid {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.bottom-bar .bar .mid .text {
	font-size: 13px;
	font-weight: 500;
	color: #000;
}
.bottom-bar .bar .mid .text.num {
	margin: 0 5px;
	color: #ff253f;
}
.bottom-bar .bar .right {
	padding: 0 10px;
	margin: 0 10px;
	color: #fff;
	background: linear-gradient(90deg, #fe8f30, #ff3384);
	font-size: 16px;
	font-weight: 500;
	height: 35px;
	line-height: 35px;
	border-radius: 20px;
}
.rectangle.active .wrapper {
	background: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 15px;
}
::v-deep .van-popup {
	position: absolute;
	z-index: 2;
}
::v-deep .van-overlay {
	position: absolute;
	background-color: rgb(70 67 67 / 70%);
}
::v-deep .van-popup--top {
	top: -1px;
	max-height: 295px;
}
.wrapper {
	overflow: auto;
}
.wrapper .item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 0;
}
.wrapper .item:first-child > div {
	color: red;
	font-size: 13px;
}
.wrapper .item .left {
	width: 40%;
	font-size: 15px;
	text-align: center;
	font-weight: 500;
	color: #000;
}
.font-weight {
	font-weight: 700 !important;
}
.wrapper .item .right {
	flex: 1;
	display: flex;
	font-size: 15px;
	justify-content: center;
	overflow: hidden;
	color: #000;
}
.wrapper .item .kuaisan-ball .left {
	justify-content: flex-start;
}
.wrapper .item .kuaisan-ball {
	margin-left: 10px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.wrapper .item .kuaisan-ball .res-img {
	width: 25px;
	height: 25px;
	margin-right: 7px;
}
.wrapper .item .kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #000;
}
.wrapper .item .kuaisan-ball .res-des.middle {
	width: 100px;
	font-size: 15px;
}
.play-type-tip {
	position: unset;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 50%;
	height: 700px;
	max-height: 50%;
	z-index: 10;
	border-radius: 30px;
	overflow: hidden;
	background-color: #fff;
	color: #000;
}
.play-type-tip .title {
	line-height: 60px;
	background: linear-gradient(to right, #fe8f30, rgb(255, 51, 132));
	text-align: center;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
}
.mask {
	background-color: rgb(0 0 0 / 0%);
	animation-duration: 0.35s;
}
.play-type-tip .wrapper {
	background-color: transparent;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.play-type-tip .wrapper .item {
	padding: 20px 20px;
	display: flex;
	align-items: flex-start;
}
.play-type-tip .wrapper .item .van-icon {
	color: #c24491;
	font-size: 20px;
}
.play-type-tip .wrapper .item .content .content-title {
	margin-top: 22px;
	font-size: 15px;
	font-weight: 500;
	color: #000;
	line-height: 0px;
}
.play-type-tip .wrapper .item .content .content-detail {
	margin-top: 5px;
	font-size: 22px;
	color: #000;
	line-height: 30px;
}
.play-type-tip .wrapper .item .content {
	flex: 1;
	margin-left: 30px;
}
.rectangle.active .wrapper {
	background-color: #ff253f !important;
}
.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
	color: #fff !important;
}
.bottom-bar .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 5px 15px;
	height: 50px;
	background-color: #fff;
	z-index: 1;
	transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
	box-shadow: 0 0 10px 0 #cacaca;
}
.bottom-bar .wrapper.active {
	transform: translateY(-100%);
	background: #fff;
}
.bottom-bar .wrapper .item {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 35px;
	padding: 0;
}
.bottom-bar .wrapper .item .label {
	font-size: 13px;
	line-height: 30px;
	color: #000;
}
.bottom-bar .wrapper .item .bet-number {
	flex: 1;
	margin: 0 16px;
	overflow: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	color: #ff253f;
	font-size: 13px;
	font-weight: 500;
	height: 25px;
	line-height: 25px;
}
.bottom-bar .wrapper .item .amount-wrapper {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.van-cell {
	font-size: 13px;
	line-height: 27px;
	padding: 1px 5px;
	border: 1px solid #d5d5d5;
	border-radius: 3px;
}
.bottom-bar .wrapper .item .part {
	margin-right: 10px;
	min-width: 55px;
}
.bottom-bar .wrapper .item .part:last-child {
	margin-right: 0;
	width: 100px;
	text-align: right;
}
.bottom-bar .wrapper .item .part span {
	font-size: 13px;
	vertical-align: center;
	color: #000;
}
.bottom-bar .wrapper .item .part .number {
	margin: 0 5px;
	color: #ff253f;
	font-weight: 500;
}
::v-deep .van-field__control {
	color: #ff253f;
}
.confirm-order-modal {
	position: unset;
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 15px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: 450px;
	min-width: 300px;
	max-height: 50%;
	z-index: 10;
	background-color: #fff;
	font-size: 14px;
}
.content-confirm > div {
	display: flex;
	padding: 5px;
}
.content-confirm .left {
	width: 100px;
	color: #000;
	font-weight: bold;
	padding-right: 15px;
}
.content-confirm .right {
	width: calc(100% - 100px);
	color: red;
	font-weight: bold;
}
.confirm-order-modal .head {
	position: relative;
	height: 40px;
}
.confirm-order-modal p {
	font-size: 12px;
	color: #795548;
	text-align: center;
	padding: 10px 5px;
	border-top: 1px solid #999;
}
.confirm-order-modal .head .text {
	padding: 0 20px;
	height: 30px;
	line-height: 10px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: #775fd9;
}
::v-deep .confirm-order-modal .van-hairline--bottom::after {
	border-bottom-width: 2px;
}
.van-popup--center {
	border-radius: 0;
	bottom: 0;
	top: auto;
	left: 0;
	transform: none;
	width: 100%;
}
.confirm-order-modal .list {
	flex: 1;
	padding: 0 10px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.confirm-order-modal .list .lise-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}
.confirm-order-modal .list .lise-item .main {
	flex: 1;
	overflow: hidden;
}
.confirm-order-modal .list .lise-item .main .bet-name {
	color: #ff253f;
	font-size: 16px;
	font-weight: 500;
	line-height: 0px;
}
.confirm-order-modal .list .lise-item .main .detail-text {
	line-height: 0px;
	font-size: 13px;
	color: #979799;
}
.confirm-order-modal .list .lise-item {
	color: #ff253f;
}
.confirm-order-modal .sub-bar {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.confirm-order-modal .item {
	min-width: 40%;
	height: 40px;
	text-align: center;
	box-sizing: border-box;
	border-radius: 20px;
	font-size: 15px;
	font-weight: 500;
}
.confirm-order-modal .sub-bar .item.cancel-btn {
	border: 2px solid #979799;
	color: #979799;
	background-color: #fff;
}
.wrapper-step2 p {
	font-size: 12px;
	color: #795548;
	text-align: center;
}
.item.sub-btn {
	background: linear-gradient(90deg, #fe8f30, #ff3384);
	color: #fff;
	border: 0;
	width: 100%;
	justify-content: center;
}
.next-number span {
	font-size: 19px;
	font-weight: 700;
	color: #000;
	float: right;
}
.brands {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	padding: 15px;
}
/*.brands > div {
  border: 1px solid #f1c8c8;
  width: 100%;
  aspect-ratio: 1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}*/
.brands > div {
	border: 1px solid #f1c8c8;
}
.brands > div img {
	width: 100%;
	height: 100%;
}
.head-title {
	text-align: center;
	padding: 10px;
	position: relative;
	background: #f3f3f3;
}
.head-title h3 {
	color: #000;
	font-size: 15px;
	margin: 0;
}
.head-title .van-icon-cross {
	position: absolute;
	top: 10px;
	left: 15px;
	color: #999;
	font-size: 20px;
}
</style>
